import React, {Suspense} from 'react'
import {Switch} from 'react-router'
import {Redirect, Route} from 'react-router-dom'
import history from './history'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'

const Index = React.lazy(() => import('../pages/Index/Index'))
const Login = React.lazy(() => import('../pages/Login/Login'))
const NewsEvent = React.lazy(() => import('../pages/NewsEvent/NewsEvent'))
const NewsEventDetail = React.lazy(() =>
  import('../pages/NewsEventDetail/NewsEventDetail')
)
const NoMatch = React.lazy(() => import('../pages/PageNotFound/PageNotFound'))
const Point = React.lazy(() => import('../pages/Point/Point'))
const Promotion = React.lazy(() => import('../pages/Promotion/Promotion'))
const PromotionDetail = React.lazy(() =>
  import('../pages/PromotionDetail/PromotionDetail')
)
const Prompt = React.lazy(() => import('../pages/Prompt/Prompt'))
const Redeem = React.lazy(() => import('../pages/Redeem/Redeem'))
const Redemption = React.lazy(() => import('../pages/Redemption/Redemption'))
const RedeemDetail = React.lazy(() =>
  import('../pages/RedeemDetail/RedeemDetail')
)
const RedeemGroup = React.lazy(() =>
  import('../pages/RedeemGroup/RedeemGroup')
)
const RedeemHistory = React.lazy(() =>
  import('../pages/RedeemHistory/RedeemHistory')
)
const RequestStatement = React.lazy(() =>
  import('../pages/RequestStatement/RequestStatement')
)

const SettingEmail = React.lazy(() =>
  import('../pages/Account/Setting/SettingEmail/SettingEmail')
)
const SettingMobile = React.lazy(() =>
  import('../pages/Account/Setting/SettingMobile/SettingMobile')
)
const SettingPassword = React.lazy(() =>
  import('../pages/Account/Setting/SettingPassword/SettingPassword')
)
const SettingDealer = React.lazy(() =>
  import('../pages/Account/Setting/SettingDealer/SettingDealer')
)
const SettingAddress = React.lazy(() =>
  import('../pages/Account/Setting/SettingAddress/SettingAddress')
)
const SettingAddressVat = React.lazy(() =>
  import('../pages/Account/Setting/SettingAddressVat/SettingAddressVat')
)

const SettingFacebook = React.lazy(() =>
  import('../pages/Account/Setting/SettingFacebook/SettingFacebook')
)
const LoginWithFacebook = React.lazy(() =>
  import('../pages/LoginWithFacebook/LoginWithFacebook')
)

const Account = React.lazy(() => import('../pages/Account/Account.jsx'))
const Logout = React.lazy(() => import('../pages/Logout/Logout.jsx'))
const Register = React.lazy(() => import('../pages/Register/Register.jsx'))
const PointHistory = React.lazy(() => import('../pages/PointHistory/PointHistory.jsx'))
const RedemptionCategory = React.lazy(() => import('../pages/RedemptionCategory/RedemptionCategory.jsx'))
const Consent = React.lazy(() => import('../pages/Account/Consent/Consent.jsx'))
const ConsentView = React.lazy(() => import('../pages/Account/Consent/ConsentView/ConsentView.jsx'))

const routes = [
  {
    path: '/redeem/:redeemId',
    component: RedeemDetail,
    exact: true,
  },
  {
    path: '/redemption/group/:redemptionGroupId',
    component: RedeemGroup,
    exact: true,
  },
  {
    path: '/redeem/category/:RedemptionCategoryTitle/:RedemptionCategoryId',
    component: RedemptionCategory,
    exact: true,
  }, {
    path: '/index/point-history',
    component: PointHistory,
  },
  {
    path: '/redeem',
    component: Redemption,
  }, {
    path: '/logout',
    component: Logout,
  }, {
    path: '/register',
    component: Register,
  }
  , {
    path: '/account/setting/facebook',
    component: SettingFacebook,
  }, {
    path: '/account/consent',
    component: Consent,
    exact: true,
  }, {
    path: '/account/consent/:id',
    component: ConsentView,
    exact: true,
  },
  {
    path: '/redeem-history',
    component: RedeemHistory,
  },
  {
    path: '/index/request-statement',
    component: RequestStatement,
  },
  {
    path: '/news-event/:contentType/:contentId',
    component: NewsEventDetail,
  },
  {
    path: '/news-event',
    component: NewsEvent,
  },
  {
    path: '/promotion/:promotionId',
    component: PromotionDetail,
  },
  {
    path: '/promotion',
    component: Promotion,
  },
  {
    path: '/account/setting/dealer/',
    component: SettingDealer,
  },
  {
    path: '/account/setting/email',
    component: SettingEmail,
  },
  {
    path: '/account/setting/mobile',
    component: SettingMobile,
  },
  {
    path: '/account/setting/password',
    component: SettingPassword,
  },
  {
    path: '/account/setting/address',
    component: SettingAddress,
  },
  {
    path: '/account/setting/address-vat',
    component: SettingAddressVat,
  },
  {
    path: '/account',
    component: Account,
  },
  {
    path: '/point',
    component: Point,
  },
  {
    path: '/login/:user/:token',
    component: Login,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/facebook',
    component: LoginWithFacebook,
  },
  {
    path: '/index',
    component: Index,
  },
  {
    path: '/',
    component: Index,
    exact: true,
  },
  {
    path: '/prompt',
    component: Prompt,
  },
  {
    path: '/error',
    component: NoMatch,
  },
  {
    component: NoMatch,
  },
]

const RouteAll = () => {
  return (
  <Suspense fallback={``}>
    <ScrollToTop />
    <Switch>
      <Route history={history} path="/login/:user/:token" exact component={Login}/>
      <Route history={history} path="/login" exact component={Login}/>
      <Route history={history} path="/register" exact component={Register}/>
      <Route history={history} path="/facebook" exact component={LoginWithFacebook}/>
      {routes.map(({component: Component, ...rest}, index) => (
        <Route
          history={history}
          {...rest}
          key={index}
          render={props => {
            const isAuth = localStorage.getItem('AUTH_TOKEN')

            if(!isAuth){
              if (props.location.search) {
                const fullPath = `${props.location.pathname}${props.location.search}`
                localStorage.setItem('REDIRECT', fullPath)
              } else {
                const pathname = window.location.pathname
                localStorage.setItem('REDIRECT', pathname)
              }
            }

            return isAuth ? (
              <Component {...props} routes={rest.routes || null}/>
            ) : (
              <Redirect to={{
                pathname: '/login',
                search: props.location.search ? 
                  `?redirect=${encodeURIComponent(props.location.pathname + props.location.search)}` : 
                  `?redirect=${props.location.pathname}`
              }}/>
            )
          }}
        />
      ))}
    </Switch>
  </Suspense>
) }

export default RouteAll
